<template>
  <div class="resource">
    <div class="cover-box">
       <img  :src="cover" class="cover-img"/>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
/* api */
import { getCover } from '@/api/home'

const cover = ref('')
const getImage = () => {
  getCover({ type: 0 }).then(res => {
      if (res.data.code === 0) {
        cover.value = res.data.data
      }
  })
}
getImage()
</script>
<style lang="scss" scoped>
.resource {
  width: 100%;
  height: calc(100% - 70px);
  .cover-box{
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .cover-img{
    width: 1200px;
    margin: 0 auto;
    height: auto;
  }
  :deep .t-image__wrapper{
    background: #fff;
  }
}
</style>
